












































import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component({
    name: 'api',
    metaInfo: {
        title: 'API - Stellarbeat.io',
        meta: [
            {name: 'description', content: 'Connect your app through our api'},
        ],
    },
})
export default class Api extends Vue {
}
